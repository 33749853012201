import React from 'react';
import { Link } from 'gatsby';

const Header = () => (
  <nav className='navbar container'>
    <div className='navbar-menu is-active'>
      <div className='navbar-start is-flex'>
        <Link to='/' className='navbar-item'>Inicio</Link>
        <Link to='/talks' className='navbar-item'>Charlas anteriores</Link>
        <a
          href='https://forms.gle/ymTfkXCrRKe87W6M6'
          target='_blank'
          rel='noopener noreferrer'
          className='navbar-item'>
          Dar una charla
        </a>
      </div>
    </div>
  </nav>
);

export default Header;
